/* VARIABLES */
$max-width: 64rem;
$max-width-content: 40rem;
$width-branding: 25rem;

$triggerGridChange: 10*64px;
$triggerGridChangeLarge: 14*64px;

/* text colors */
$txt-color: #000;
$txt-color-inverted: #FFF;
$txt-color-brand: #8C6A55;

/* backgorund colors */

$bg-color: #000;
$bg-color-brand: #D5DDD0;
$bg-color-brand-light: #D5DDD0;
$bg-color-brand-dark: #8C6A55;
$bg-color-brand-green: #99CC33;
$bg-color-focus: #CA2334;
$bg-color-inverted: #FFF;
$bg-color-header: #F98A14;
$bg-color-footer: #CCC;

/* border colors */

$border-color-gray: #CCC;
$shadow-color-dark: rgba($bg-color-brand-dark, 0.6)
//FORMS

.content--form {
    
}

.form {
    &__columns {

    }

    &__column {

    }

    .form-row {
        display: flex;
        margin: 0.25rem 0;
    }

    &.form-row--pet {
        align-items: center;
    }

    .form-column {
        flex: 1;
        padding-right: 1rem;
    }

    .form-label {
        font-weight: bold;
        font-size: $fontSizeReduced;
        text-transform: uppercase;
    }

    input {
        width: calc(100% - 1rem);
        border: solid 1px #CCC;
        padding: 0.5rem;
        border-radius: 4px;
        font-family: $basefontStyled;
        font-size: 1.15rem;

        &::placeholder {
            font-style: italic;
            font-size: 1.15rem;
        }
    }

    textarea {
        width: 15rem;
        border: solid 1px #CCC;
        padding: 0.5rem;
        border-radius: 4px;
        font-family: $basefontStyled;

        &::placeholder {
            font-style: italic;
            font-size: $fontSizeReduced;
        }
    }

    .--required {
        &::after {
            content: " *";
            color: #FF0000;
        }
    }
}

.form-field {
    &--error {
        color: #FF0000;
        font-size: 0.8rem;
    }
}

.product__action-buttons {
    &.--cta {
        display: flex;
        justify-content: center;
    }
}
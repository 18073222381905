//LIST PRODUCTS

.list-products {
    display: grid;
    grid-template-columns: 1fr 1fr;

    &__group {
        grid-column: 1 / span 2;
        display: grid;
        grid-template-columns: 3fr 1fr 1fr 1fr;
    }

    &__header {
        font-size:  2rem;
        grid-column: 1 / span 4;
        text-align: center;

        + .list-products__list {
            margin-top: 1rem;
        }
    }

    &__intro {
        grid-column: 1 / span 4;

        p {
            text-align: center;
        }
    }

    &__list {
        grid-column: 1 / span 4;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 1rem;
        margin-bottom: 1rem;

        @media only screen and (min-width: (10 * 64px)) {
            grid-template-columns: 1fr;
        }
    }

    &__listitem {
        margin: 0 -1rem 1rem;   
        padding: 1rem;
        border: solid 1px rgba(#CCC, 1);
        border-radius: 0.5rem;

        @media only screen and (min-width: (10 * 64px)) {
            display: grid; 
            grid-gap: 2rem;
            grid-template-columns: 3fr 2fr;
        }
    }

    .content__alert {
        grid-column: 1 / span 4;
    }

    .product__summary,
    .product__summary--list,
    .product__contents {
        list-style-type: none;
        padding: 0;
        margin: 0;
        width: 100%;
    }

    .product__subheader {
        display: block;
        text-transform: uppercase;
        font-size: 0.75rem;
        font-weight: bold;
        padding: 0;
    }

    .product__price {
        font-size: 2rem;
        margin: 0;
        padding: 0;
        margin-top: -0.2rem;
    }

    .product__specs {
        display: flex;
        flex-direction: column;
        font-size: 0.9rem;

        > div {
            padding: 0.5rem 0;
        }

        span {
            grid-column: 1;

            &:first-child {
                padding-top: 0;
            }
        }

        &--price {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            span {
                padding-top: 0;
                line-height: 1;
            }
        }

        &--ingredients {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-top: solid 1px #CCC;
            padding: 0.5rem 0;

            span:last-child {
                text-align: right;
                margin-top: -0.15rem;
            }
        }

        &--contents {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-top: solid 1px #CCC;
            display: flex;
            padding: 0.5rem 0;
        }

        &--packaging {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-top: solid 1px #CCC;
            padding: 0.5rem 0;

            img {
                width: 2rem;
            }
        }

        ul {
            grid-column: 2;
            display: grid;
            grid-template-columns: 1fr 2fr 1fr;
        }
      
        li {
            text-align: right;

            &:nth-child(1) {
                grid-column: 1;
            }
            &:nth-child(2) {
                grid-column: 2;
                text-align: left;
            }
            &:nth-child(3) {
                grid-column: 3;
            }
        }
    }

    .product__actions {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
    }

    .product__action-buttons {
        //margin-top: 1rem;
        grid-column: 1 / span 2;
        display: flex;
        justify-content: center;
    }
}
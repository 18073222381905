// PRODUCTS REVIEW

.products-review {
    &__columns {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    &__column {
        
        &--label {
            grid-column: 3;
        
        }

        &--amount {
            text-align: right;
        }

        &--amount-total {
            grid-column: 4;
            text-align: right;
        }
    }

    &__column-header {
        font-weight: bold;
        text-align: right;

        &--label-amount {
            text-align: right;
        }
    }
}
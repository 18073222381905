/* UNDER CONSTRUCTION */

.page-content {
    &--under-construction {
        img {
            width: 100%;
        }

        &__notification {
            margin-top: 3rem;
            width: 90%;
            margin: auto;
            padding-bottom: 2rem;
            @media only screen and (min-width: (12 * 64px)) {
                width: 75%;
            }
            h2,
            p {
                
                color: #000;
                font-family: $basefontStyled;
            }

            h2 {
                line-height: 2.35rem;
                margin-bottom: 1rem;
            }

            a {
                color: $txt-color-brand;
                font-weight: bold;
            }
        }
    }
}

.under-construction {
    &__swiper {
        position: fixed;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .swiper-slide {
            &__img {
                display: flex;
                min-width: 100%;
                height: 80vh;
                background-size: cover;
                background-position: center center;
                img {
                    flex: 1
                }
            }
        }

        .swiper-pagination {
            display: none;
        }

        .swiper-button-next,
        .swiper-button-prev {
            display: none;
        }
    }
}

.branding {
    &__logo-img {
        margin: auto;
    }

    &__logo-payoff {
        margin: auto;
    }
}
/* TYPOGRAPHY */

/* TYPOGRAPHY */
$fontDir: "../fonts/Calibri";
$fontDir2: "../fonts/OpenSans";

/* fonts */

@font-face {
    font-family: 'Calibri';
    src: url('#{$fontDir}/Calibri-Light.woff2') format('woff2'),
        url('#{$fontDir}/Calibri-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Calibri';
    src: url('#{$fontDir}/Calibri-Italic.woff2') format('woff2'),
        url('#{$fontDir}/Calibri-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Calibri';
    src: url('#{$fontDir}/Calibri-LightItalic.woff2') format('woff2'),
        url('#{$fontDir}/Calibri-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Calibri';
    src: url('#{$fontDir}/Calibri.woff2') format('woff2'),
        url('#{$fontDir}/Calibri.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Calibri';
    src: url('#{$fontDir}/Calibri-BoldItalic.woff2') format('woff2'),
        url('#{$fontDir}/Calibri-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Calibri';
    src: url('#{$fontDir}/Calibri-Bold.woff2') format('woff2'),
        url('#{$fontDir}/Calibri-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans';
    src: url('#{$fontDir2}/OpenSansCondensed-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans';
    src: url('#{$fontDir2}/OpenSansCondensed-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$basefont: '-apple-system', 'HelveticaNeue', 'RobotoRegular';
$basefontStyled: 'OpenSans';

html {
    font-size: 16px;
    font-size: calc(14px + 0.35vw);
}

body {
    font-size: 1.15rem;
    font-family: $basefontStyled;
    font-size: 1.15rem;
}

h1,
h2,
.h2,
p {
    font-family: $basefontStyled;
    font-weight: normal;
    color: $txt-color;

    @at-root .theme--light & {
        color: $txt-color;
    }
}

p {
    line-height: 1.6rem;
    font-size: 1.15rem;
}

.btn {
    font-family: $basefontStyled;
}

$fontSizeReduced: 0.8rem;

/* LAYOUT */

body {
	margin: 0;
	padding: 0;
	background-color: $bg-color-brand-light;
	color: $txt-color-inverted;
}

.wrapper {
	&--branding {
		height: 20vh;
		width: 20rem;
		display: flex; 
    	flex-direction: column;
    	justify-content: center;
    	align-items: center;
    	margin: auto;
	}
}

.page-wrapper {
	display: flex;
    flex-direction: column;
	
	&--under-contruction {
		width: 100%;
		height: 100%;
	}
}

.page-content {
	min-height: 100vh;
	//margin-top: 36vh;
	margin-top: 20rem;
	padding: 2rem 0 5rem;

	&--products {
		padding: 0;
	}

	&--under-construction {
		position: relative;
		z-index: 100;
		text-align: center;
		font-size: 1.5rem;	
		background-color: rgba(#D5DDD0, 0.9);

		width: 100%;
		min-height: 100vh;
		margin-top: 60vh;
	}

	&--cart {
		
	}

	&__branding {
		position: relative;
		width: $width-branding;
		z-index: 100;
		
		min-height: 8rem;
		background-color: rgba(#FFF, 0.9);
		display: flex;
		margin: auto;
		padding-bottom: 2rem;
    	border-radius: 0 0 0.4rem 0.4rem;

		.branding__logo-img {
			height: 60%;
			img {
				height: 100%
			}
		}

		.branding__logo-payoff {
			height: 20%;
			margin: -0.25rem auto auto auto;
			img {
				height: 100%
			}
		}
	}
}

.segment--content,
.segment--content-header {
	width: calc(100% - 4rem);
	padding: 0 2rem;
	max-width: $max-width-content; 
	margin: auto;

	&-products {
		@media only screen and (min-width: (16 * 64px)) {
			max-width: $max-width-content; 
		}
	}

	.content__header {
		font-size: 2rem;
	}

	.content__text {
		margin: 1rem 0 2rem;
	}
}

.segment--content-header {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	height: calc(22rem + 20vh);
	top: 0;
	z-index: 10;
	display: flex;
	align-items: flex-end;

	h1 {
		width: 62%;
		font-weight: bold;
		color: #FFF;
		margin-bottom: 2.25rem;
	}
}

.segment--content {
	&__products-header {
		width: 100%;
		max-width: 100%;
		padding: 0;
		background-color: #EEE;
		margin-bottom: 2rem;

		.products-header__content {
			max-width: $max-width-content;
			padding: 2rem;
			margin: auto;
		}
		
	}

	&__columns {
		display: flex;
		flex-direction: column;
		width: 100%;

		@media only screen and (min-width: ($triggerGridChange)) {
			flex-direction: row;	

			@at-root .--table {
				flex-direction: column;

				.form {
					display: grid;
					grid-template-columns: 1fr 1fr;
				}
			}
		}

		@media only screen and (min-width: ($triggerGridChangeLarge)) {
			width: 100%;	
		}

		

	}

	&__column {
		flex: 1;

		&:first-child {
			flex: 1;
		}

		&--images {
			margin-top: 2rem;
			padding: 0;
			width: 100%;

			display: flex;
    		justify-content: center;
    		flex-wrap: wrap;

			@media only screen and (min-width: ($triggerGridChange)) {
				//margin-top: -8rem;
				padding: 0 0 0 2rem;
				display: block;
			}

			@media only screen and (min-width: ($triggerGridChangeLarge)) {
				margin-right: -6rem;	
			}

			img {
				height: 15rem;
				border: solid 3px #FFF;
				border-radius: 0.4rem;
			}
		}
	}

	&__images {
		width: 100%;
		max-width: 100%;
		margin: 2rem 0;
		padding: 0;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	&__image {
		min-width: 10rem;
		margin: 0.25rem;
		flex: 1;
		height: 15rem;
		background-size: cover;
		background-position: center;
	}

	&__quote {
		min-width: 10rem;
		margin: 0.25rem;
		flex: 1;
		height: 15rem;
		background-color: $bg-color-brand-green;
		color: #FFF;
		//font-weight: bold;
		font-size: 1.75rem;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;

		span {
			position: relative;
			top: -0.5rem;
		}
	}

	+ .segment--content {
		margin-top: 2rem;
	}
}

.form {
	position: relative;
}


// SBC CART OVERVIEW

.cart-products {
    &__columns {
        display: grid;
        grid-template-columns: 6fr 3fr 3fr 3fr;

        &--productline {
            position: relative;
            padding: 0.5rem;
            margin: 0.5rem -0.5rem;
        }

        &--totals {
            grid-template-columns: 2fr 1fr;
            @media only screen and (min-width: (10 * 64px)) {
                grid-template-columns: 6fr 3fr 3fr 3fr;
            }
        }
    }

    &__column {
        display: flex;
        flex-direction: column;
        text-align: left;
        position: relative;
        justify-content: center;

        &--label {
            grid-column: 1;
            text-align: left;
            @media only screen and (min-width: (10 * 64px)) {
                grid-column: 3;
                text-align: right;
            }
        }

        &--amount {
            text-align: right;
            
        }

        &--amount-total {
            grid-column: 4;
            text-align: right;
        }

        &--full {
            grid-column: 1 / 4;
        }

        input {
            width: 3rem;
            height: 1.5rem;
            text-align: right;
        }
    }

    &__column-header {
        font-weight: bold;
        text-align: left;
        display: block;

        &--amount {
            text-align: right;
        }
    }
}
// HEADER

.header-content {
    &__navigation--main {
        position: absolute;
        width: $width-branding;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        font-family: $basefontStyled;
        color: #000;
        font-size: 0.85rem;
        background-color: #FFF;
        border-radius: 0 0 0.4rem 0.4rem;
        display: flex;

        &.--sticky {
            position: fixed;
            top: 0;
            z-index: 999;
            height: 2.2rem;
            background-color: #EEE;
        }
    }

    
    &__navigation--cart {
        // position: absolute;
        // bottom: 0;
        // right: 0;

        // &.--sticky {
        //     position: fixed;
        //     top: 0;
        //     z-index: 999;
        //     height: 2.2rem;
        //     background-color: #EEE;
        // }
    }
}

.navigation--main {
    &__list {
        list-style-type: none;
        display: inline-block;
        width: 100%;
        margin: 0;
        padding: 0; 
        height: 2.2rem;
    }

    &__listitem {
        //flex: 1;
        position: relative;
        display: flex;
        //width: 50%;
        padding: 0.1rem 0.5rem 0.5rem;
        background-size: 1.5rem;
        background-repeat: no-repeat;
        background-position: center;
        //margin: 0.2rem;
        float: left;

        span {
            padding: 0.4rem 0.5rem 0.5rem;
        }

        &:first-child {
            border-radius: 0 0 0 0.4rem;
        }

        &:last-child {
            border-radius: 0 0 0.4rem 0;
        }
        &:last-child {
            padding: 0;
            float: right;
        }

        // &--home {
        //     background-image: url('../images/icons/ic-home.png');
        // }

        // &--products {
        //     background-image: url('../images/icons/ic-products.png');
        // }
    }

    &__cart {
        width: 5rem;
        list-style-type: none;
        display: inline-block;
        margin: auto;
        padding: 0; 
        height: 2.2rem;
        //background-color: $bg-color-brand-green;
        border-radius: 0 0 0.4rem 0;
        display: flex;
        justify-content: center;
        align-items: center;

        li {
            display: flex;
        }

        img {
            height: 1.35rem;
            margin: 0 0.5rem;
        }
    }

    &__cart-counter {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $bg-color-brand-green;
        color: #FFF;
        width: 0.5rem;
        height: 0.5rem;
        font-weight: bold;
        border-radius: 50%;
    }
}
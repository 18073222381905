// PAYMENT 

/* PAYMENT OPTIONS */

.payment-issuers,
.payment-methods {
    display: block;

    &__list {
        display: flex;
        flex-flow: row wrap;
        margin: 0 -0.25rem;
    }

    &__listitem {
        flex: 1;
        margin: 0.25rem;
        padding: 0.25rem;
        min-width: 6rem;
        max-width: 6rem;
        height: 4rem;
        border: solid 1px #CCC;
        background-color: #FFF;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        label {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-width: 0;
            cursor: pointer;
            input[type="radio"] {
                display: none;
            }
            img {
                height: 2rem;
                max-width: 4rem;
            }
            span {
                font-size: $fontSizeReduced;
                text-align: center;
                line-height: 1;
                margin: 0.4rem 0 0;
            }
        }

        &.--selected {
            outline: solid 2px #000;
        }
    }
}
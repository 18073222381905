//FORMS LAYOUT

.form {
    &__columns {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 0px 2rem;
        padding: 0.25rem 0;

        @media only screen and (min-width: ($triggerGridChange)) {
			grid-template-columns: 1fr;
		}
    }

    .form-column {
        grid-column: 1;
    }

    .form-label {
        @at-root .--table & {
            display: none;
        }
    }
}

.form-row {
    &--buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        max-width: 40rem;
        margin: 1rem 0;

        .btn {
            margin-left: 0.5rem;
        }
    }
}
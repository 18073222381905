// CART REVIEW

.container-cart-review {
    .container-cart__content {
        &--columns {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }

        &--column {
            grid: 1;

        }
    }
}
/* SHOPPING CART */

.wrapper--cart {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.container-cart {
    width: 100%;
    border-top: solid 1px #CCC;
    border-bottom: solid 1px #CCC;
    margin-top: 7rem;
    //padding: 0 25%;
    background-color:rgba(#FFF, 0.7);
    
    &__header {
        text-align: left;
        padding: 0.9rem 0 1.1rem;
        display: flex;
        flex-direction:column;

        h1 {
            font-size: 2rem;
            padding: 0;
            font-weight: normal;
            
        }
    }

    .form-row--buttons {
        margin: 1rem 0 2rem;
    }
}

.page-cart {
    text-align: left;

    &__header {
        height: 15rem;
        padding: 0 calc((100vw - 80vw) / 2);
    }
}

.cart-section {
    padding: 0 calc((100vw - 80vw) / 2);
}

.cart-row {
    display: flex;
    flex-direction: row;
}

.order-row {
    &__article,
    &__amount,
    &__price-unit,
    &__price-total {
        flex: 1;
    }

    &__article {

    }

    &__amount {
        text-align: right;
    }

    &__price-unit {
        text-align: right;
    }

    &__price-total {
        text-align: right;
    }

    &__order-total-title {
        flex: 1;

    }

    &__order-total-prices {
        flex: 1;
        text-align: right;
    }
}
// SBC BUTTONS

.btn {
    padding: 0.5rem;
    border: solid 1px #000;
    border-radius: 0.4rem;
    font-size: 0.85rem;

    &--primary {
        border: none;
        background-color: $bg-color-brand-green;
        color: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;

        &.--active {
            cursor: pointer;
        }

        &.--focus {
            //font-size: 1.5rem;
            padding-bottom: 0.75rem;
        }

        &.--disabled {
            background-color: #CCC;
        }
        &.--inactive {
            background-color: #CCC;
        }
    }
}

.action-button--cta {
    display: flex;
    justify-content: flex-end;
    max-width: 40rem;
    margin: 0 auto 2rem;

    .btn {
        font-size: 1rem;
    }
}
/* FOOTER */

.wrapper--footer {
    position: relative;
    width: 100%;
    background-color: #CCC;
    padding: 5rem 0 2rem 0;

    .footer-content {
     
    }

    .navigation--footer {
        &__list {
            list-style-type: none;
            padding: 0;
            margin: 0;
            color: #000;
        }

        &__listitem {
            &--logo {
                width: 8rem;
                position: absolute;
                right: 3rem;
                top: 4rem;

                @media only screen and (min-width: ($triggerGridChange)) {
                    position: relative;	
                    right: auto;
                    top: auto;
                }

                img {
                    width: 100%;
                }
            }
        }
    }
	
}
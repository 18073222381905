// GENERAL CONTENT

.--link {
    cursor: pointer;
}

.page-content {
    position: relative;
    background-color: #FFF;
    color: #000;

    p {
        color: #000;
    }
}

.content {
    &__productlist {
        ul {
            list-style-type: none;
            margin-left: 0;
            margin-right: 0;
            padding: 0;
        }
    }

    &__alert {
        margin: 0 -1rem 2rem;
        border: solid 1px #CCC;
        border-radius: 0.4rem;
        padding: 0 1rem;

    }

    &__actions {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.product {
    &__header {
        font-weight: bold;
    }

    &__description {
        margin-bottom: 0.5rem;
    }

    // &__specs {
    //     color: #000;
    //     display: flex;
    //     flex-direction: row;
    //     justify-content: space-between;
    //     //border: solid 1px green;
    //     align-items: center;

    //     span {
    //         flex: 2;
    //     }

    //     ul {
    //         flex: 1;
    //         justify-content: flex-end;
    //     }
    // }

    &__summary {
        margin-bottom: 1rem;
    }

    &__contents {
        color: #000;
        display: flex;
        flex-direction: row;

        li {
            margin-left: 1rem;
        }

        &.contents__list {
            display: flex;
            align-items: center;

        }

        .contents__listitem {
            &--icon {
                width: 2rem;
                display: flex;
                //border: solid 1px red;

                img {
                    width: 100%;
                }
            }
        }
    }
}

.icon {
    position: absolute;
    right: -1.5rem;
    background-size: 100% 100%;
    background: left center no-repeat;
    width: 1rem;
    height: 1rem;
    display: block;
    
    &--delete {
        background-image: url('../images/icons/cross-black.svg');
        z-index: 10;

        @at-root .form-row--pet & {
            right: -0.5rem;
        }
    }
}
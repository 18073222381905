// CART OVERVIEW

.container-cart {
    &__content {
        display: block;
        border: solid 1px #AAA;
        border-radius: 5px;
        padding: 1rem;

        &--form {
            max-width: 30rem;
            margin: auto;
        }

        @at-root .page-content--review & {
            margin: 1.5rem 0 0;
        }

        .cart-actions {
            display: flex;
            justify-content: flex-end;
        }
    }

    &__products {

    }

    &__totals {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

        &--subtotal,
        &--shipping,
        &--total {
            display: grid;
            grid-column: 4 / span 2;
            grid-template-columns: 1fr 1fr;
        }

        &--shipping {

        }

        &--total {

        }

        &--header {
            grid-column: 1;
        }

        &--price {
            grid-column: 2;
        }

    }
}